(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/error-handling/assets/javascripts/error-boundary-fallback.js') >= 0) return;  svs.modules.push('/modules/oddset/components/error-handling/assets/javascripts/error-boundary-fallback.js');
"use strict";


const Icon = svs.ui.ReactIcon;
const Button = svs.ui.ReactButton;
const ErrorBoundaryFallback = _ref => {
  let {
    retry
  } = _ref;
  return React.createElement("div", {
    className: "fc-white align-center error-screen padding-3"
  }, React.createElement(Icon, {
    color: "fc-white",
    icon: "exclamation-sign",
    size: "900"
  }), React.createElement("h2", {
    className: "f-900 margin-v-1"
  }, "N\xE5got gick fel"), React.createElement("p", {
    className: "f-500 margin-bottom-2"
  }, "Tj\xE4nsten svarar inte p\xE5 grund av ett systemfel."), React.createElement(Button, {
    inverted: true,
    onClick: retry,
    size: 300
  }, "F\xF6rs\xF6k igen"));
};
setGlobal('svs.oddset.components.error_handling.ErrorBoundaryFallback', ErrorBoundaryFallback);

 })(window);